///// <reference types="arconnect" /> 

import { Injectable } from '@angular/core';
declare let require: any;
// const Arweave = require('arweave');
import  Arweave from 'arweave';
import { PermissionType } from 'arweave/node/transactions';
import { timeEnd } from 'console';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../environments/environment';
import { WalletDetails } from '../models/wallet-details';
import { readContract, interactWrite, interactWriteDryRun } from 'smartweave';

// Replace this Id with the contract you want to read.

const realCommunityIdPBt = "0GRLIbU0jmipa-jBuNjrshdBsrw96HTSVdCyLniF1Sg";
const testCommunityIdPBot = "_iiAhptMPS95AxLXjX7bMPBZ5gyh_X2XXmrQeootpFo";
const targetWalletAddress = environment.targetWalletAddress; // 'ivuY1YVWN-kMWNMwV6smGTmOAL-EtiLe4sZ2T3S54_E';

@Injectable({
  providedIn: 'root'
})
export class ArweaveService {
  arweave;
  isWalletLoaded$ =new BehaviorSubject(false);
  isWalletReady$ =new BehaviorSubject(false);

  // arLocalWalletDb: WalletDB ;

  walletDetails$: BehaviorSubject<WalletDetails> =new BehaviorSubject(null);

  constructor() {
    this.arweave = Arweave.init({
      host: environment.arweaveHost,// Hostname or IP address for a Arweave host
      port: environment.arweavePort,          // Port
      protocol: environment.arweaveProtocol,  // Network protocol http or https
      timeout: 20000,     // Network request timeouts in milliseconds
      logging: true,     // Enable network request logging
    });
  }

  async setWalletLoaded(status: boolean){
    this.isWalletLoaded$.next(status);
    if(status){
      let perms = await window.arweaveWallet.getPermissions();
              
      if (perms.length == 3) {
          await this.loadWalletDetails();
          this.isWalletReady$.next(true);
      }
      
    }
  }

  async loadWalletDetails(){
    let address = await window.arweaveWallet.getActiveAddress();

    this.walletDetails$.next({
      address: address,
      permissions: ['READ'],
      balance: this.arweave.ar.winstonToAr( await this.arweave.wallets.getBalance(address) )
    });
    
    // this.setWalletLoaded(true);
    
  }

  

  async connecToWallet(permissions: Array<PermissionType> ){
    await window.arweaveWallet.connect(permissions, { name: "wAR BSC" });
      
    try {
      await this.loadWalletDetails();
    } catch (err) {
        console.error('Error Loading Wallet details :', err);
        alert('Error Loading arConnect Wallet details ')
    }
  }

  get getArweaveBurnFee(){
    return environment.arweaveFee;
  }

  async swap(coin: string, amount: string, destinationBscWallet: string ){
    
    // Send 10.5 AR to 1seRanklLU_1VTGkEk7P0xAwMJfA7owA1JHW5KyZKlY
    const fee = environment.turn_arweave_off ? 0:(environment.arweaveFee * parseFloat(amount));
    const amountPlusFee = parseFloat(amount) + fee;
    if(coin==='AR'){
      let transaction = await this.arweave.createTransaction({
        target: targetWalletAddress,
        quantity: this.arweave.ar.arToWinston(amountPlusFee.toFixed(12))
      });

      transaction.addTag("Application", "wAR - BSC");
      transaction.addTag("Action", "MINT");
      transaction.addTag('Wallet', destinationBscWallet);
      transaction.addTag('Coin', coin);

      await this.arweave.transactions.sign(transaction);

      const response = await this.arweave.transactions.post(transaction);
      
      console.log(response.status, ", data: ", response.data);

      let t = transaction.id;
      return {
        status: response.status,
        txId: t,
        data: response.data,
        
      };
    }else if(coin ==='PBT'){
      let tags = [
        {
          name:'Application', 
          value:'wAR - BSC'
        },
        {
          name:"Action", 
          value: "MINT"
        },
        {
          name:'Wallet', 
          value: destinationBscWallet
        },
        {
          name: 'Coin',
          value: coin
        }
      ]

      try{
        // let winstoneFee =  Math.max(1,  Math.ceil((environment.arweaveFee * parseFloat(amount)) ) );
        let pstFee = this.calculatePSTFeeCharged(+amount, environment.arweaveFee );
        const responseTxId = await interactWrite(this.arweave,'use_arconnect',realCommunityIdPBt,{ "function": "transfer", "qty": Math.ceil(+amount + pstFee ), "target": targetWalletAddress } ,tags, targetWalletAddress );
        console.log('responseTxId: ', responseTxId ); 
        return {
          status: 'success',
          txId: responseTxId,
          data:{} 
        };
      }catch(errr){
        // console.error(errr);
        return {
          status: 'failed',
          txId: '',
          data:{}
        };
      }

    }

    return {
      status: 'failed',
      txId: null,
      data:{}
    };

  }

  calculatePSTFeeCharged  (
    totalAmount: number,
    PERCENTFEE: number
  ): number {

    if(environment.turn_arweave_off || PERCENTFEE==0){
      return 0;
    }
    
    let FEE = 1; // totalAmount <=101
    
    if(totalAmount <=101){
        FEE=1;
    }
    else if(totalAmount <=202){
        FEE=2;
    }else if(totalAmount >202){
        FEE = Math.ceil(totalAmount - (totalAmount/(PERCENTFEE+1) ) ) ;
    }
  
    return FEE;
  
  }

}



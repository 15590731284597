<div class="container-fluid text-center">

  <div class="row mb-4 mt-4">
    <div class="col-12 d-flex justify-content-center">
      <img src="/assets/images/wAr PSC Logo.png" class="img-fluid" style="max-width: 120px;"/>

    </div>
    <div class="col-12 text-center">
      <h2 style="color: darkblue;">Arweave to Binance Smart Chain Bridge</h2>
    </div>
    <div class="col-12 text-center d-flex justify-content-center">
      <a href="/home#howto" class="text-danger  mx-2">How to use</a>
      <div class="px-2">|</div>
      <a href="/home#about" class="text-danger ">About</a>
    </div>

    <div class="col-12 mt-4 alert  alert-success">Swaps on this bridge are free till Oct 30, 2021</div>
    <!-- <div class="col-12 text-center">
      <button (click)="testAlert()">Test Alert</button>
    </div> -->
  </div>

  

  <div class="row mt-4">
    <div class="col-12 col-sm-6 mt-4">
      <div class="card text-white bg-primary">
        <div class="card-header">
          <h2 class="text-left title">Arweave Coin to Wrapped Coin (BSC)</h2>
          <div class="d-flex justify-content-between">
            
            <div *ngIf="( arweaveService.isWalletLoaded$ | async ) as isWalletLoaded">
              <div class="row" *ngIf="( arweaveService.walletDetails$ | async ) as walletDetails">
                <div class="col-6 overflow-text">{{walletDetails.address}}  </div>
                <div class="col-6"><em>( {{walletDetails.balance}} AR)</em></div>
              </div>

              <div *ngIf="!( arweaveService.isWalletReady$ | async )">
                <button class="btn btn-primary arweave-button" style="border-color: #f7fbf8;" (click)="connecToWallet()">
                  <img src="/assets/images/arconnect.jpg" class="img-fluid  px-1" style="max-width: 1.5rem;"/>
                  ArConnect</button>
              </div>
            </div>
            
          </div>
          
        </div>
        <div class="card-body bg-primary-faint">
          <div class="card m-3 text-white bg-primary">     
      
            <h3 class="card-header">
                <div class="d-flex justify-content-between">
                  <div>Swap </div>
                  <div><strong>Estimated Fee:</strong> {{(arweaveService.getArweaveBurnFee * 100)}} % </div>
                </div>
            </h3>
            <div class="card-body">
        
                <form *ngIf="form" [formGroup]="form" (ngSubmit)="onSubmit()">

                  <div class="form-group text-left">
                    <label for="arcoin">Coin</label>
                    <select formControlName="arcoin" *ngIf="arCoins.length>0" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.arcoin.errors }">
                      <option *ngFor="let op of arCoins" [value]="op.value">
                        {{ op.text }}
                      </option>
                    </select>
          
                    <div *ngIf="submitted && f.arcoin.errors" class="invalid-feedback">
                      <div *ngIf="f.arcoin.errors.required">Coin is required</div>
                    </div>
                      
                  </div>


                    <div class="form-group text-left">
                      <label class="text-left">Destination BSC Wallet Address</label>
                      <input type="text"   formControlName="destBscAddress" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.destBscAddress.errors }" />
                      <div class="text-center">
                        <small  >Address of your BSC Wallet, Wrapped PSTs or wAR will be sent to this address</small>
                      </div>
                      <div *ngIf="submitted && f.destBscAddress.errors" class="invalid-feedback">
                        <div *ngIf="f.destBscAddress.errors.required">BSC Wallet is required</div>
                        <div *ngIf="f.destBscAddress.errors.minlength">Invalid Wallet Address Length</div>
                      </div>
                    </div>


                    

                    <div class="form-group text-left">
                      <label>Amount (AR or PST)</label>
                      <input type="text"   formControlName="amount" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.amount.errors }" />
                      <small >Amount that will be sent to this address</small>
                      <div *ngIf="submitted && f.amount.errors" class="invalid-feedback">
                        <div *ngIf="f.amount.errors.required">Amount is required</div>
                        <div *ngIf="f.amount.errors.min">Min Amount is 0.01 or 1 if Coin is a PST</div>
                        <div *ngIf="f.amount.errors.max">Max Amount is 1000000</div>
                      </div>
                    </div>
                    
      
                    <div class="form-row">
                        <div class="form-group col">
                            <button [disabled]="!( arweaveService.isWalletReady$ | async ) || loading" class="btn btn-primary arweave-button"  style="border-color: #f7fbf8;">
                                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                                Submit
                            </button>
                            
                        </div>
                        
                    </div>
                </form>
            </div>
        
          </div>

        </div>
      </div>

      
    </div>

    <div class="col-12 col-sm-6 mt-4">
      <div class="card text-white bg-success">
        <div class="card-header">
          <h2 class="text-left title">Wrapped Coin (BSC) to Arweave Coin</h2>
          <div class="d-flex justify-content-between">
            
            <div *ngIf="(bscService.walletEnabled$ | async ) as isWalletLoaded   ">
              <div class="row" *ngIf="bscAddress">
                <div class="col-7 overflow-text">{{bscAddress}}  </div>
                
              </div>

              
            </div>
            <div *ngIf="!(bscService.walletEnabled$ | async )">
              <button class="btn btn-success" style="border-color: #f7fbf8;" (click)="connectMetaMask()" title="Connect Meta Mask">
                <img src="/assets/images/metamask.png" class="img-fluid px-1" style="max-width: 1.5rem;"/>
                Connect Metamask
              </button>
            </div>
            
          </div>
          <div *ngIf="!validNetwork" class="mt-2 alert alert-warning"><small>Unsupported network!</small></div>
          
        </div>
        <div class="card-body bg-success-faint">
          <div class="card m-3 text-white bg-success">     
      
            <h3 class="card-header">
                <div class="d-flex justify-content-between">
                  <div>Swap </div>
                  <div><strong>Estimated Fee:</strong> 0.003 BNB </div>
                </div>
            </h3>
            <div class="card-body">
        
                <form *ngIf="bscForm" [formGroup]="bscForm" (ngSubmit)="onSubmitBSCForm()">
                  <div class="form-group text-left">
                    <label for="bsccoin">Coin</label>
                    <select formControlName="bsccoin" *ngIf="bscCoins.length>0" class="form-control" [ngClass]="{ 'is-invalid': bscSubmitted && fbsc.bsccoin.errors }">
                      <option *ngFor="let op of bscCoins" [value]="op.value">
                        {{ op.text }}
                      </option>
                    </select>
          
                    <div *ngIf="bscSubmitted && fbsc.bsccoin.errors" class="invalid-feedback">
                      <div *ngIf="fbsc.bsccoin.errors.required">Coin is required</div>
                    </div>
                      
                  </div>

                  <div class="form-group text-left">
                    <label>Destination Arweave Wallet Address</label>
                    <input type="text"   formControlName="destArAddress" class="form-control" [ngClass]="{ 'is-invalid': bscSubmitted && fbsc.destArAddress.errors }" />
                    <small >Address of your Arweave Wallet, PSTs or AR Coins will be sent to this address</small>
                    <div *ngIf="bscSubmitted && fbsc.destArAddress.errors" class="invalid-feedback">
                      <div *ngIf="fbsc.destArAddress.errors.required">Arweave Wallet is required</div>
                      <div *ngIf="fbsc.destArAddress.errors.minlength">Invalid Wallet Address Length</div>
                    </div>
                  </div>

                  

                  <div class="form-group text-left">
                    <label>Amount (wAR or Wrapped PST)</label>
                    <input type="text"   formControlName="amount" class="form-control" [ngClass]="{ 'is-invalid': bscSubmitted && fbsc.amount.errors }" />
                    <div ><small >Amount that will be sent to this address</small></div>
                    <div *ngIf="bscSubmitted && fbsc.amount.errors" class="invalid-feedback">
                      <div *ngIf="fbsc.amount.errors.required">Amount is required</div>
                      <div *ngIf="fbsc.amount.errors.min">Min Amount is 0.01 or 1 if Coin is PST</div>
                      <div *ngIf="fbsc.amount.errors.max">Max Amount is 1000000</div>
                    </div>
                  </div>
                    
      
                    <div class="form-row">
                        <div class="form-group col">
                            <button [disabled]="!(bscService.walletEnabled$ | async ) || bscloading" class="btn btn-success" style="border-color: #f7fbf8;">
                                <span *ngIf="bscloading" class="spinner-border spinner-border-sm mr-1"></span>
                                Submit
                            </button>
                            
                        </div>
                        
                    </div>
                </form>
            </div>
        
          </div>

        </div>
      </div>
    </div>
  </div>

  <div class="row mt-8 text-how-to" id="howto">
    <div class="col-12 text-left ">
      <h2 class="title text-center mb-4" style="font-size: 2rem; font-weight: 600;">How to use</h2>

      <div class="row">
        <div class="col-12 col-sm-6">
          <div class="card">
            <div class="card-header"><strong>To swap from Arweave to Binance Smart Chain</strong></div>
            <div class="card-body">
              <ol class="">
                <li class="">Click the ArConnect button to login with your ArConnect Arweave wallet</li>
                <li class="">Choose the Coin you want to move to BSC</li>
                
                <li class="">Input the destination BSC Wallet address</li>
                <li class="">Enter in the amount of Arweave based Coin you want to swap</li>
                <li class="">Click on Submit to complete</li>
              </ol>
            </div>
          </div>
          

          
        </div>

        <div class="col-12 col-sm-6">
          <div class="card">
            <div class="card-header"><strong>To swap from Binance Smart Chain to Arweave</strong></div>
            <div class="card-body">
              <ol class=" ">
                <li class="">Click the Metamask button to login with your BSC wallet</li>
                <li class="">Choose the Coin you want to move to Arweave</li>
                
                <li class="">Input the destination Arweave Wallet address</li>
                <li class="">Enter in the amount of Arweave based Coin you want to swap</li>
                <li class="">Click on Submit to complete</li>
              </ol>
            </div>
          </div>

          

          
        </div>

      </div>
    </div>
  </div>

  <div class="row mt-8 text-how-to" id="about">
    <div class="col-12 text-left ">
      <h2 class="title text-center mb-4 mt-4" style="font-size: 2rem; font-weight: 600;">About</h2>

      <div class="row">
        <div class="col-12 col-sm-12">
          <div class="card">
            
            <div class="card-body ">
              <div class="card-text">
                WARBridge allows you to migrate Arweave Coin and Arweave based Profit sharing Tokens (PSTs) to and fro, from the Arweave to the Binance Smart Chain. 
              </div>

              <div class="card-text">
                Fees are distributed to holders of the 
                <a href="https://community.xyz/#3mXVO90b-n-aSUTMStO3SLe-tUMduYV5aSWB9n74XXk">WAR-BSC profit sharing community</a> 
              </div>
            </div>
          </div>
          

          
        </div>

        

      </div>
    </div>
  </div>
    
  
</div>
import { Component, Inject, OnInit } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-bsc-to-war-summary-dialog',
  templateUrl: './bsc-to-war-summary-dialog.component.html',
  styleUrls: ['./bsc-to-war-summary-dialog.component.scss']
})
export class BscToWarSummaryDialogComponent implements OnInit {

  injectedData: any;
  constructor( private dialogRef: MatDialogRef<BscToWarSummaryDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: any) { 

      this.injectedData=data;
    }

  ngOnInit(): void {

  }

  save() {
    this.dialogRef.close("Ok");
  }

  close() {
      this.dialogRef.close();
  }

}

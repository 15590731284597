export const environment = {
  production: true,
  apiUrl: 'https://localhost:44311/api',
  arweaveFee: 0.005,  // 0.5%
  arweaveHost: 'arweave.net',
  arweavePort: '443',
  arweaveProtocol: 'https',

  targetWalletAddress: 'PigC7o4R1rranKeoFLhuGqot2KOx_ZAp9xf_KP7bIyY',

  bscCoinContractsAddresses : {
    "wAR": '0x7209331e2f3B4Bb86ac1EaD771e81fFae1dDeE8D',
    "PBT": '0x2d7AAC7415449b65f9Dd8A83b7920B11b162eD00'

  },
  turn_arweave_off : true,

  turn_bsc_fee_off : true
};

import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { ArweaveService } from '../../../app/services/arweave.service';
import { AlertService, BSCService } from '../../services';

import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { environment } from '../../../environments/environment';
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import { WarToBscSummaryDialogComponent } from '../war-to-bsc-summary-dialog/war-to-bsc-summary-dialog.component';
import { BscToWarSummaryDialogComponent } from '../bsc-to-war-summary-dialog/bsc-to-war-summary-dialog.component';
import { AlertDialogComponent } from '../alert-dialog/alert-dialog.component';
declare let window: any;


@Component({
  selector: 'app-start',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.scss']
})
export class StartComponent implements OnInit {

  bscAddress: string;

  form: FormGroup;
  bscForm: FormGroup;
  loading = false;
  bscloading=false;
  submitted = false;
  bscSubmitted = false;

  arCoins = [
    {
      value: 'AR',
      text:'Arweave'
    },
    {
      value: 'PBT',
      text:'PermaBot (PBT)'
    },
  ];

  bscCoins = [
    {
      value: 'wAR',
      text:'Wrapped Arweave'
    },
    {
      value: 'PBT',
      text:'PermaBot (PBT)'
    },
  ];

  validNetwork = true;

  constructor(public arweaveService: ArweaveService,
              private alertService: AlertService,
              public bscService: BSCService,
              private formBuilder: FormBuilder,
              private dialogService: MatDialog) { }



  async ngOnInit(): Promise<any>  {
    this.form = this.formBuilder.group({
      destBscAddress: ['', [Validators.required, Validators.minLength(25)]],
      amount: ['10', [Validators.required, Validators.min(0.01), Validators.max(100000000)]],
      arcoin: ['PBT',[Validators.required]]
    });

    this.bscForm = this.formBuilder.group({ 
      destArAddress: ['', [Validators.required, , Validators.minLength(25)]],
      amount: ['1', [Validators.required, Validators.min(0.01), Validators.max(100000000)]],
      bsccoin: ['PBT',[Validators.required]]
    });
    

    this.bscService.walletEnabled$.subscribe(async (r)=>{
      if(r){
        this.bscAddress = await this.bscService.getAccount().catch((err)=>{});
      }else{
        this.bscAddress = '';
      }
    })


    window.ethereum.on('accountsChanged', async  (accounts: any) => {
      // Time to reload your interface with accounts[0]!
      console.log('Account Changed .  ', accounts);
      window.location.reload();
    })

    window.ethereum.on('networkChanged', function (networkId: any) {
        // Time to reload your interface with the new networkId
        //console.log('Network Changed .  new: ', networkId);
        window.location.reload();

        
    })


    window.web3.eth.getChainId().then((chainId:any )=>{
      // console.log('ChainId: ', chainId);

      if( this.bscService.validNetworks.findIndex(c=>c==chainId)<0){
        alert('Wrong Network!');
        this.validNetwork = false;
      }else{
        this.validNetwork = true;
      }
    })
    

    await this.connectMetaMask();

  }

  async connectMetaMask(){
    const b = await this.bscService.enableMetaMaskAccount();
    if(this.bscService.walletEnabled$.value){
      this.bscAddress = await this.bscService.getAccount();
    }
    
  }

  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }

  get fbsc() { return this.bscForm.controls; }

  async connecToWallet(){
    try {
      
      await this.arweaveService.connecToWallet(["ACCESS_ADDRESS", "ACCESS_ALL_ADDRESSES", "SIGN_TRANSACTION"]);

      window.location.reload();
    } catch (err) {
        console.error('Error Connecting to ArConnect :', err);
        this.alertService.error('Error Connecting to ArConnect.');
    }
  }


  


  onSubmit() {
    if( this.form.invalid == false && this.f.arcoin.value !=='AR'){
      alert("Swapping Permabot is currently disabled till after the private sale. ");
      return;
    }

    this.submitted = true;

    // reset alerts on submit
    this.alertService.clear();

    // stop here if form is invalid
    if (this.form.invalid) {
        return;
    }

    let fee = environment.arweaveFee * parseFloat(this.f.amount.value);
    if(this.f.arcoin.value!=='AR'){
      fee= this.arweaveService.calculatePSTFeeCharged(this.f.amount.value, environment.arweaveFee);
    }

    

    let dialogData = {
      coin: this.arCoins.find(ff=>ff.value==this.f.arcoin.value).text ,
      symbol: this.f.arcoin.value,
      amount: this.f.amount.value,
      wallet: this.f.destBscAddress.value,
      fee: fee
    };

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = dialogData;

    const dialogRef = this.dialogService.open(WarToBscSummaryDialogComponent, dialogConfig);


    dialogRef.afterClosed().subscribe(
        data => {
          // console.log("Dialog output:", data);
          if(data==="Ok"){
            this.loading = true;
            this.arweaveService.swap(this.f.arcoin.value, this.f.amount.value, this.f.destBscAddress.value).then((result)=>{
              this.loading=false;
              if(result.status===200 || result.status==='success'){
                this.alertService.success(`Arweave Swap Completed. TX Hash: ${result.txId} !`, {autoClose: false});
                // alert(`Swap Completed. TX Hash: ${result.txId}`);
                this.showAlert(`Swap Completed. TX Hash: ${result.txId}`, 'Success');
              }else{
                this.alertService.error('Swap Failed!', {autoClose: false});
              }
              
              // this.alertService.success(`Arweave Swap Completed. TX Hash: ${result.transactionHash}`);
              // setTimeout (() => {
              //   this.router.navigateByUrl('/home');
              // }, 1500);
            }).catch((error: any)=>{
              console.error(error);
                this.alertService.error(error, {autoClose: false});
                this.loading = false;
            });
          }
        }
    );  


    // if( confirm(`Are you sure you want to Swap ${this.f.amount.value} ${this.f.arcoin.value} for its Wrapped BSC Equivalent?`)){
      
    // }

    
        
  }



  onSubmitBSCForm() {

    

    this.bscSubmitted = true;
   
    // reset alerts on submit
    this.alertService.clear();

    // stop here if form is invalid
    if (this.bscForm.invalid) {
        return;
    }

    let fee = environment.arweaveFee * parseFloat(this.fbsc.amount.value);
    if(this.fbsc.bsccoin.value!=='wAR'){
      fee= this.arweaveService.calculatePSTFeeCharged(this.fbsc.amount.value, environment.arweaveFee);
    }
    

    let dialogData = {
      coin: this.bscCoins.find(ff=>ff.value==this.fbsc.bsccoin.value).text ,
      symbol: this.fbsc.bsccoin.value,
      amount: this.fbsc.amount.value,
      wallet: this.fbsc.destArAddress.value,
      fee: fee
    };

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = dialogData;

    const dialogRef = this.dialogService.open(BscToWarSummaryDialogComponent, dialogConfig);


    dialogRef.afterClosed().subscribe(
        data => {
          // console.log("Dialog output:", data);
          if(data==="Ok"){
            this.bscloading = true;
            this.bscService.burnWAR(this.fbsc.bsccoin.value,this.fbsc.amount.value, this.fbsc.destArAddress.value).then((result)=>{

              // console.log(result);

              this.bscloading=false;
              this.alertService.success(`Swap Completed. TX Hash: ${result.receipt.transactionHash}`, {autoClose: false});
              this.showAlert(`Swap Completed. TX Hash: ${result.receipt.transactionHash}`, 'Swap Completed');
              // this.alertService.success(`Arweave Swap Completed. TX Hash: ${result.transactionHash}`);
              // setTimeout (() => {
              //   this.router.navigateByUrl('/home');
              // }, 1500);
            }).catch((error: any)=>{
                this.alertService.error(error);
                this.bscloading = false;
            });
            
          }
        }
    );  

    // if(confirm(`Are you sure you want to Swap ${this.fbsc.amount.value} wAR back for its equivalent AR?`)){
    //   this.bscloading = true;
    //   this.bscService.burnWAR(this.fbsc.amount.value, this.fbsc.destArAddress.value).then((result)=>{

    //     // console.log(result);

    //     this.bscloading=false;
    //     this.alertService.success(`Swap Completed. TX Hash: ${result.receipt.transactionHash}`, {autoClose: false});
    //     // this.alertService.success(`Arweave Swap Completed. TX Hash: ${result.transactionHash}`);
    //     // setTimeout (() => {
    //     //   this.router.navigateByUrl('/home');
    //     // }, 1500);
    //   }).catch((error: any)=>{
    //       this.alertService.error(error);
    //       this.bscloading = false;
    //   });
    // }

    
        
  }


  showAlert(message: string, title?: string, successful: boolean=true){
    let dialogData = {
      title,
      message,
      successful
    };

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;

    dialogConfig.data = dialogData;

    const dialogRef = this.dialogService.open(AlertDialogComponent, dialogConfig);


    dialogRef.afterClosed().subscribe(
        data => {
          // console.log("Dialog output:", data);
          
        }
    );
  }



}

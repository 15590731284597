<h2 mat-dialog-title>Swap {{injectedData.amount}} {{injectedData.symbol}} for its Arweave Equivalent?</h2>

<div class="row">
    <div class="col-12">
        <mat-dialog-content >
  
            <dl>
                <dt>Coin</dt>
                <dd>{{injectedData.coin}} </dd>

                <dt>ArWeave Wallet</dt>
                <dd> {{injectedData.wallet}}</dd>

                <dt>Amount</dt>
                <dd>{{injectedData.amount | number}} {{injectedData.symbol}}</dd>

                <dt>Fee</dt>
                <dd>0.003 BNB</dd>
            </dl>
        
         
        </mat-dialog-content>
        
        <mat-dialog-actions>
            <button class="mat-raised-button "(click)="close()">Cancel</button>
            <button class="mat-raised-button mat-primary ml-2"(click)="save()">Confirm</button>
        </mat-dialog-actions>
    </div>
</div>
